import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Glyphicon} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Dropdown from './dropdown';

export const BaseMenuDropdown = ({children, includeFeedback, logoutUrl, onToggle, mixpanel}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);

  const handleSelect = (link) => {
    handleToggle(false);
  };

  const handleToggle = (open) => {
    setOpen(open);
    onToggle && onToggle(open);
  };

  const handleLogout = () => {
    mixpanel?.reset(); // Reset tracked user identity
  };

  const menuItems = React.Children.map(children, (link) => {
    if (link.type) {
      const {onClick, isRailsView, ...linkProps} = link.props || {};
      const wrappedOnClick = (...props) => {
        handleSelect();
        if (onClick) {
          return onClick(...props);
        }
      };
      return (
        <li key={link.key} onClick={handleSelect}>
          <link.type {...linkProps} onClick={wrappedOnClick} />
        </li>
      );
    } else {
      return (
        <li key={link.key || link} onClick={handleSelect}>
          {link}
        </li>
      );
    }
  });

  const footerItems = [];
  if (includeFeedback) {
    footerItems.push(
      <li key="feedback" onClick={handleSelect}>
        <a href="http://bit.ly/CC_FeedbackForm" target="_blank" className="minor">
          {t('menu.feedback')}
        </a>
      </li>,
      <li key="about" onClick={handleSelect}>
        <a href="https://classcatalyst.com/privacy-policy/" target="_blank" className="minor">
          {t('menu.about_cc')}
        </a>
      </li>,
    );
  }

  footerItems.push(
    <li key="logout">
      <a href={logoutUrl} data-method="delete" rel="nofollow" className="minor" onClick={handleLogout}>
        {t('menu.logout')}
      </a>
    </li>,
  );

  const buttonTitle = (
    <div className="open-or-close">
      <span className="when-closed icon menu-open" aria-hidden="true" />
      <span className="when-opened icon menu-close" aria-hidden="true" />
      <span>{t('menu.menu')}</span>
    </div>
  );

  return (
    <Dropdown
      noCaret
      bossMode
      open={open}
      title={buttonTitle}
      id="menu-dropdown"
      className="nav-dropdown"
      items={menuItems}
      footerItems={footerItems}
      onToggle={handleToggle}
      onSelect={handleSelect}
    />
  );
};

BaseMenuDropdown.propTypes = {
  onToggle: PropTypes.func,
  includeFeedback: PropTypes.bool,
  logoutUrl: PropTypes.string,
  mixpanel: PropTypes.object,
};

BaseMenuDropdown.defaultProps = {
  currentCourseId: undefined,
  courses: [],
  includeFeedback: true,
  logoutUrl: '/accounts/users/sign_out',
};

export default BaseMenuDropdown;
