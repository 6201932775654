import React, {useState} from 'react';
import PropTypes from 'prop-types';

import BaseMenuDropdown from '../shared/base-menu-dropdown';

import {buildMenuItems} from './menu-dropdown-items';

export const MenuDropdown = ({
  onToggle,
  currentPoll,
  currentCourseId,
  currentUserOverride,
  schoolId,
  schoolInfoId,
  districtId,
  isRailsView,
  menuItemGenerator,
  mixpanel,
}) => {
  const items = buildMenuItems({
    onToggle,
    currentPoll,
    currentCourseId,
    currentUserOverride,
    schoolId,
    schoolInfoId,
    districtId,
  });

  const menuItem = menuItemGenerator();

  return (
    <BaseMenuDropdown onToggle={onToggle} mixpanel={mixpanel}>
      {items.map(menuItem)}
    </BaseMenuDropdown>
  );
};

MenuDropdown.propTypes = {
  currentCourseId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentUserOverride: PropTypes.object,
  currentPoll: PropTypes.object,
  onToggle: PropTypes.func,
  isRailsView: PropTypes.bool,
  menuItemGenerator: PropTypes.func,
  schoolId: PropTypes.number,
  schoolInfoId: PropTypes.number,
  mixpanel: PropTypes.object,
};

MenuDropdown.defaultProps = {
  currentCourseId: undefined,
  currentUserOverride: undefined,
  isRailsView: false,
  schoolId: undefined,
  schoolInfoId: undefined,
  onToggle: undefined,
};

export default MenuDropdown;
