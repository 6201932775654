import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {join, times, isEmpty} from 'lodash';

export const DropdownFooter = ({footerItems}) => {
  return (
    <li className="dropdown-footer">
      <ul>{footerItems}</ul>
    </li>
  );
}

export const AccordionFooter = ({footerItems, openText, closeText}) => {
  const [showFooter, setShowFooter] = useState(false);

  const toggleFooter = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowFooter(!showFooter);
  };

  const btnText = showFooter ? closeText : openText;

  return Boolean(footerItems) && (
    <div className="accordion-footer">
      <div className="accordion-btn" onClick={toggleFooter}>{btnText}</div>
      {showFooter && <DropdownFooter footerItems={footerItems} />}
    </div>
  );
};

export const DropdownButton = ({children, title, open, ...props}) => {
  const openClassName = open ? 'open' : '';

  return (
    <div className={join(['dropdown', 'btn-group', openClassName], ' ')}>
      <button role="button" aria-haspopup="true" aria-expanded={open} type="button" className="nav-dropdown dropdown-toggle btn btn-default">
        {title}
      </button>
      <ul role="menu" className="dropdown-menu">
        {children}
      </ul>
    </div>
  );
};

DropdownButton.propTypes = {
  children: PropTypes.array.isRequired,
  title: PropTypes.node,
  open: PropTypes.bool,
};

export default class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: false};
  }

  handleToggle = (e) => {
    const open = !this.state.open;
    this.setState({open});
    this.props.onToggle && this.props.onToggle(open);
  };

  render() {
    const {items, footerItems, accordionFooter, openText, closeText, bossMode, id, ...otherProps} = this.props;

    let footer;
    if (accordionFooter) {
      footer = <AccordionFooter footerItems={footerItems} openText={openText} closeText={closeText} />;
    } else if (footerItems) {
      footer = <DropdownFooter footerItems={footerItems} />;
    }

    return (
      <div className={bossMode && 'bossMode'} id={id}>
        <div className="btn-group" onClick={this.handleToggle}>
          {this.state.open && <div className="behind-dropdown" />}
          <DropdownButton open={this.state.open} {...otherProps}>
            {items}
            {footer}
          </DropdownButton>
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  title: PropTypes.node,
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.node),
  footerItems: PropTypes.arrayOf(PropTypes.node),
};
