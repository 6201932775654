import React, {StrictMode, useMemo} from 'react';
import MenuDropdown from './menu-dropdown';

import {I18nextProvider} from 'react-i18next';
import i18n from '../../i18n';

export const userIsStaff = () => {
  const currentUser = $('#current_user');
  return currentUser?.data('teacher') || currentUser?.data('counselor');
};

// Get data necessary for Staff users
export const getStaffData = () => {
  if (!userIsStaff()) {
    return {currentPoll: null, currentCourseId: null};
  }

  const dataset = $('#course_data');
  const courses = dataset?.data('courses') || [];
  const course = courses?.length && courses[0];
  const currentCourseId = course?.id;
  const currentPoll = course?.polls && course?.polls[0];

  return {currentPoll, currentCourseId};
};

const MenuDropdownStandalone = (...props) => {
  const menuItemGenerator = () => {
    let n = 0;
    const generator = (item) => {
      if (item === undefined) {
        return <div></div>;
      }
      const {label, path, ...args} = item;
      if (label === undefined) {
        n++;
        return <div key={`separator-${n}`} className="separator" />;
      }
      return (
        <a key={label} href={path} {...args}>
          {label}
        </a>
      );
    };
    return generator;
  };

  const {id: userId, schoolId, schoolInfoId, districtId, ...userData} = $('#current_user').data();
  const isStaff = userIsStaff();
  const staffData = useMemo(getStaffData, [userId, isStaff]);

  return (
    <I18nextProvider i18n={i18n}>
      <StrictMode>
        <MenuDropdown {...props} menuItemGenerator={menuItemGenerator} schoolId={schoolId} schoolInfoId={schoolInfoId} districtId={districtId} {...staffData} />
      </StrictMode>
    </I18nextProvider>
  );
};

export default MenuDropdownStandalone;
