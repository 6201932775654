import sendPoll from '../utils/ajax-methods/send-poll';
import startGroupActivity from '../utils/ajax-methods/start-group-activity';

export function buildMenuItems({
                                 onToggle,
                                 currentPoll,
                                 currentCourseId,
                                 schoolId,
                                 schoolInfoId,
                                 districtId,
                                 currentUserOverride,
                               }) {
  const onSendPollClick = (e) => {
    e.preventDefault();
    sendPoll(currentCourseId);
  }
  const onGroupActivityClick = (e) => {
    e.preventDefault();
    startGroupActivity(currentPoll.id);
  };

  const currentUser = currentUserOverride || $('#current_user');
  currentUser.data ||= (value) => currentUser[value]; // For override case

  const roles = currentUser.data('roles') || [];
  const isTeacher = currentUser.data('teacher') || false;
  const isAdministrator = currentUser.data('administrator') || false;
  const isHuddleCoach = currentUser.data('huddleCoach') || false;
  const isCounselor = currentUser.data('counselor') || false;
  const isDistrictSetupManager = roles.includes('DistrictSetupManager');
  const isSetupManager = currentUser.data('setupManager') || false;
  const isCCStaff = currentUser.data('ccStaff') || false;
  const isAppAdmin = currentUser.data('appAdmin') || false;
  const accountId = currentUser.data('accountId');

  const shouldShowSelfCare = currentUser.data('shouldShowSelfCare') || false;

  const featureFlagsData = $('#feature_flags').data();
  const newTeacherAbilities = featureFlagsData ? featureFlagsData.newTeacherAbilities : false;

  const classViewPath = currentCourseId !== undefined ? `/courses/${currentCourseId}` : '/';
  const items = [];

  if (roles.includes('VideoOnly')) {
    return [
      {label: 'Video Library', path: '/video_dashboard', className: 'minor'},
    ];
  }

  if (roles.includes('CurriculumOnly')) {
    return [
      {label: 'Activities', path: '/activities_description', className: 'minor'},
    ];
  }


  if (isCCStaff || isAppAdmin || isSetupManager) {
    items.push(
      {label: 'Admin', header: true},
      {label: 'Accounts', path: '/accounts', className: 'minor'}
    );
    if (schoolId !== undefined) {
      let schoolInfoPath = `/schools/${schoolId}/school_info/new`;
      if (schoolInfoId !== undefined) {
        schoolInfoPath = `/schools/${schoolId}/school_info/${schoolInfoId}`;
      }
      items.push(
        {label: 'School Info', path: schoolInfoPath, className: 'minor'},
        {label: 'Courses', path: `/schools/${schoolId}/courses`, className: 'minor'},
        {label: 'Students', path: `/schools/${schoolId}/students`, className: 'minor'},
        {label: 'Teachers', path: `/schools/${schoolId}/teachers`, className: 'minor'},
        {label: 'Managers', path: `/schools/${schoolId}/teacher_managers`, className: 'minor'},
        {label: 'Counselors', path: `/schools/${schoolId}/counselors`, className: 'minor'},
        {label: 'School Administrators', path: `/schools/${schoolId}/administrators`, className: 'minor'},
      );
    }
    if (isCCStaff || isAppAdmin || isDistrictSetupManager) {
      items.push({label: 'District Administrators', path: `/districts/${districtId}/administrators`, className: 'minor'});
    }
    if (schoolId !== undefined) {
      items.push(
        {label: 'Create User', path: `/schools/${schoolId}/users/new`, className: 'minor'}
      );
    }
    if (isCCStaff || isAppAdmin || isDistrictSetupManager) {
      items.push({label: 'Add New School', path: `/accounts/${accountId}/districts/${districtId}/schools/new`, className: 'minor'});
    }
    if (isAppAdmin) {
      items.push({label: 'Rails Admin', path: '/admin', className: 'minor'})
    }
  }

  if (isTeacher) {
    items.push(
      {label: 'Teacher', header: true},
      {label: 'Communications', path: '/notifications', className: 'minor', key: 't_com'},
      {label: 'Class View', path: classViewPath, className: 'minor'},
    );
    if (currentCourseId !== undefined) {
      items.push(
        {label: 'Request Check-in', path: '#', className: 'minor', onClick: onSendPollClick},
      );
      if (currentPoll && currentPoll.id) {
        items.push(
          {label: 'Start Group Activity', path: '#', className: 'minor', onClick: onGroupActivityClick},
        );
      }
      items.push(
        {label: 'Start Kiosk Mode', path: `/courses/${currentCourseId}/kiosk`, className: 'minor'},
        {label: 'Check-in View', path: `/courses/${currentCourseId}/checkins`, className: 'minor'},
        {label: 'Student View', path: `/courses/${currentCourseId}/students`, className: 'minor'},
      );
      if (isSetupManager) {
        const newStudentPath = `/schools/${schoolId}/courses/${currentCourseId}/users/new`;
        items.push(
          {label: 'New Student', path: newStudentPath, className: 'minor', key: 'sm_newstudent'},
        );
      }
    }
    items.push(
      {label: 'Analytics', path: '/analytics', className: 'minor', key: 't_analytics'},
      {label: 'Assessments', path: '/assessment_report', className: 'minor', key: 't_assess'},
    );
  }
  if (isAdministrator) {
    items.push(
      {label: 'Administrator', header: true},
      {label: 'Communications', path: '/notifications', className: 'minor', key: 'a_com'},
      );
    if (currentCourseId !== undefined) {
      if (isSetupManager) {
        const newStudentPath = `/schools/${schoolId}/courses/${currentCourseId}/users/new`;
        items.push(
          {label: 'New Student', path: newStudentPath, className: 'minor', key: 'a_newstudent'},
        );
      }
    }
    items.push(
      {label: 'Analytics', path: '/analytics', className: 'minor', key: 'a_analytics'},
      {label: 'Assessments', path: '/assessment_report', className: 'minor', key: 'a_assess'},
    );
  }
  if (isHuddleCoach) {
    items.push(
      {label: 'Student Huddle', path: '/student-huddle'},
    );
  }
  if (isCounselor) {
    items.push(
      {label: 'Counselor', header: true},
      {label: 'Dashboard', path: '/counseling', className: 'minor'},
      {label: 'Analytics', path: '/counselor-analytics', className: 'minor', key: 'c_analytics'},
      {label: 'Huddle Coaches', path: `/schools/${schoolId}/huddle_coaches`, className: 'minor', isRailsView: true},
    );
  }
  items.push(
    {label: 'Resources', header: true},
    {label: 'Activities', path: '/activities_description', className: 'minor'},
    {label: 'Weekly SEL Calendar', path: '/weekly_calendar', className: 'minor'},
    {label: 'Video Library', path: '/video_dashboard', className: 'minor'},
  );
  if (shouldShowSelfCare) {
    items.push({}, {label: 'Self Care', path: '/checkins'});
  }
  items.push({label: 'Settings', path: '/teacher_admin'});
  if (isSetupManager) {
    items.push({label: 'Master Student List', path: `/schools/${schoolId}/students`, className: 'minor'});
  }
  return items;
}
